import React, { useEffect } from "react";
import { useMedia } from "react-use";
import Link from "next/link";
import clsx from "clsx";
import {
  ExternalLinkBlock,
  InternalLinkBlock,
  useGetHeaderMenuQuery,
  useGetFooterSettingsQuery,
} from "graphql/generated-types";

import Logo from "@/icons/menzie-ms-logo.svg";

type MenuLinkTypes = InternalLinkBlock | ExternalLinkBlock;
type MenuBlockTypes = MenuLinkTypes;
type HeaderProps = {
  pageType: string;
};

const Header: React.FC<HeaderProps> = ({ pageType }: { pageType: string }) => {
  const { data } = useGetHeaderMenuQuery();
  const footerData = useGetFooterSettingsQuery();
  const footerSettings = footerData?.data?.footerSettings;
  const socialMediaLinks =
    footerSettings?.socialMediaLinks as InternalLinkBlock[];
  const legalLinks = footerSettings?.legalLinks as InternalLinkBlock[];

  const [isOpen, setIsOpen] = React.useState(false);
  const isMobile = useMedia("(max-width: 1023px)", false);
  if (!isMobile && isOpen) setIsOpen(false);

  useEffect(() => {
    if (isOpen && isMobile) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isOpen, isMobile]);

  const renderMenuBlock = (block: MenuBlockTypes) => {
    switch (block.blockType) {
      case "InternalLinkBlock":
      case "ExternalLinkBlock":
        return (
          <Link
            key={block.id}
            className="underline lg:underline-link decoration-cyan underline-offset-8 lg:leading-none"
            href={block.href}
          >
            {block.text}
          </Link>
        );
      default:
        throw new Error(`Unknown menu block type: ${block.blockType}`);
    }
  };

  let transparentHeader = ["HomePage", "LandingPage"].includes(pageType);
  let headerClasses = clsx({
    "w-full rounded-b lg:rounded-b-lg transition": true,
    "absolute top-0 left-0 z-10": transparentHeader,
    "bg-transparent": !isOpen && transparentHeader,
    "bg-blue": isOpen || !transparentHeader,
    "h-screen": isOpen,
  });
  let navClasses = clsx({
    "flex flex-col justify-between text-white transition": true,
    "opacity-0 hidden": !isOpen && isMobile,
    "opacity-1 h-full": isOpen && isMobile,
  });
  let subNavClasses = clsx({
    hidden: !isOpen,
    "flex flex-col gap-1.5": isOpen,
  });

  return (
    <header className={headerClasses}>
      <div className="container flex flex-col justify-between h-full py-6 lg:flex-row lg:pb-12 lg:pt-14">
        <Link href="/">
          <Logo className="h-10 sm:h-16 lg:h-10 xl:h-16 2xl:h-20" />
        </Link>
        <div className={navClasses}>
          <div className="flex flex-col gap-20 mt-28 lg:m-0">
            <nav className="flex flex-col lg:flex-row gap-1.5 lg:gap-14 text-[24px] lg:text-base">
              {data?.menuSettings?.menu?.map((b) =>
                renderMenuBlock(b as MenuBlockTypes)
              )}
              <Link
                className="underline lg:no-underline decoration-cyan underline-offset-8 lg:leading-none"
                href="/search"
              >
                Search
              </Link>
            </nav>
            <div className={subNavClasses}>
              {socialMediaLinks?.map((link) => (
                <Link
                  key={link.id}
                  href={link?.href}
                  className="underline decoration-cyan underline-offset-8 text-[24px]"
                >
                  {link?.text}
                </Link>
              ))}
            </div>
          </div>
          <div className={subNavClasses}>
            {legalLinks?.map((link) => (
              <Link
                key={link.id}
                href={link?.href}
                className="font-light text-xxs"
              >
                {link?.text}
              </Link>
            ))}
          </div>
        </div>
        <div
          onClick={() => setIsOpen(!isOpen)}
          className="absolute leading-4 text-white top-6 right-6 lg:hidden"
        >
          Menu
        </div>
      </div>
    </header>
  );
};

export default Header;
