import Footer from "@/components/Footer";
import Header from "@/components/Header";
import { ReactNode } from "react";

type PageContainerProps = {
  children: ReactNode;
  pageType: string;
};

const PageContainer: React.FC<PageContainerProps> = ({
  children,
  pageType,
}) => {
  return (
    <>
      <Header pageType={pageType} />
      <main className="lg:pb-72 pb-44">{children}</main>
      <Footer />
    </>
  );
};

export default PageContainer;
