import {
  InternalLinkBlock,
  useGetFooterSettingsQuery,
} from "graphql/generated-types";
import Link from "next/link";
import React from "react";

import MenzieLogo from "@/icons/menzie-logo.svg";
import MSLogo from "@/icons/ms-logo.svg";

const Footer: React.FC = () => {
  const { data } = useGetFooterSettingsQuery();
  const footerSettings = data?.footerSettings;
  const socialMediaLinks =
    footerSettings?.socialMediaLinks as InternalLinkBlock[];
  const legalLinks = footerSettings?.legalLinks as InternalLinkBlock[];

  return (
    <footer className="text-white rounded-t-lg bg-blue">
      <div className="container flex flex-col gap-24 pt-16 pb-14 lg:pb-20 lg:gap-4 lg:flex-row lg:pt-28">
        <div className="flex flex-col flex-1 gap-8 lg:flex-row">
          <MenzieLogo className="max-w-[50%] lg:max-h-[70px]" />
          <MSLogo className="w-36 lg:w-auto lg:max-w-[50%] lg:max-h-[70px]" />
        </div>
        <div className="flex flex-col flex-1 gap-10">
          <p>{footerSettings?.acknowledgementOfCountry}</p>
          <div className="flex gap-36">
            <div className="flex flex-col gap-16">
              {footerSettings?.address && (
                <div
                  dangerouslySetInnerHTML={{ __html: footerSettings?.address }}
                ></div>
              )}
              {footerSettings?.contactPhone && (
                <p>Phone: {footerSettings?.contactPhone}</p>
              )}
            </div>
            <div className="flex-col hidden lg:flex gap-11">
              <div className="flex flex-col gap-3">
                {socialMediaLinks?.map((link) => (
                  <Link
                    key={link.id}
                    href={link.href}
                    className="underline decoration-cyan underline-offset-4 whitespace-nowrap"
                  >
                    {link.text}
                  </Link>
                ))}
              </div>
              <div className="flex flex-col gap-3">
                {legalLinks?.map((link) => (
                  <Link
                    key={link.id}
                    href={link.href}
                    className="underline decoration-cyan underline-offset-4 text-xxs whitespace-nowrap"
                  >
                    {link.text}
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
